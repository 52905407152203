.price_text {
  font-family: "Raleway";
  font-weight: 500;
  color: #444;
  font-size: 18px;
  text-align: center;
}

.price_btn_box {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cars_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 20px;
}

.card_top {
  position: relative;
  opacity: 1;
}

.card_top:hover .project_btn {
  opacity: 1;
  transition: all 0.3s ease 0s;
}

.card_top:hover .pr_box {
  opacity: 1;
  transition: all 0.3s ease 0s;
}

.project_btn {
  position: absolute;
  top: 35%;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
}

.pr_box {
  position: absolute;
  top: 0;
  background: rgba(38, 38, 40, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.pr_btn {
  font-family: "Raleway";
  font-weight: 700;
  color: white;
  background-color: transparent;
  transition: all 0.3s ease 0s;
  border: 1px solid white;
  padding: 15px 25px;
  border-radius: 30px;
}

.pr_btn:hover {
  background: linear-gradient(135deg, #ff8800 0%, #cf3218 100%);
  border: 1px solid #cf3218;
}

.car_card {
  width: 330px;
  height: 560px;
  box-shadow: 2px 4px 25px -13px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  margin: 0 auto;
}

.car_img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card_bottom {
  margin-top: 20px;
  padding: 0 20px;
}

.car_title {
  font-family: "Raleway";
  font-weight: 700;
  color: #444;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}

.car_detail {
  font-family: "Raleway";
  font-weight: 400;
  color: #444;
  font-size: 17px;
}

.btn_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_button_book {
  font-family: "Raleway";
  font-size: 17px;
  color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  background: linear-gradient(135deg, #ff8800 0%, #cf3218 100%);

  text-transform: uppercase;
}

.main_button_book:hover {
  background: linear-gradient(135deg, #cb540a 0%, #cf3218 100%);

  color: #eedbcb;
}

.car_price {
  font-family: "Raleway";
  font-weight: 700;
  color: #ff8800;
  font-size: 20px;
}

.details_box {
  /* border-top: 1px solid #cf3218; */
  padding: 0 20px;
}

@media screen and (min-width: 768px) and (max-width: 1199.5px) {
  .cars_box {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767.5px) {
  .cars_box {
    grid-template-columns: 1fr;
  }
}
