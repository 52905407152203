.logo {
  width: 200px;
}

.header_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: fixed; */
  /* background-color: #ff8800; */
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.menu_item {
  font-family: "Raleway";
  font-weight: 500;
  color: #444;
  transition: 0.3s ease;
  font-size: 18px;
  /* padding:  20px 0; */
  text-decoration: none;

  background-image: linear-gradient(to right, #ff8800, #ff8800 50%, #444 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  margin-right: 25px;
  position: relative;
  text-transform: uppercase;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.menu_item:before {
  content: "";
  background: #ff8800;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.menu_item:hover {
  background-position: 0;
}

.menu_item:hover::before {
  width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1199.5px) {
  .menu_item {
    font-size: 14px;
    padding: 0 10px;
  }
}
@media screen and (max-width: 767.5px) {
  .menu {
    display: none;
  }
}
/* .menu_item:last-child {
  padding-right: 0;
} */

/* .menu_item:hover {
  color: #236ad6;
  cursor: pointer;
  border-top: 3px solid #236ad6;
  border-bottom: 3px solid #236ad6;
  padding: 6px 20px;
} */
