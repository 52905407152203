.mainbanner_sec {
  background-image: url("../../images/background_banner.jpg");
  background-size: cover;
}

.banner_box {
  min-height: 700px;
  background-color: rgba(0, 0, 0, 0.5);
}

.banner_content {
  padding-top: 170px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main_title {
  font-family: "Raleway";
  font-size: 60px;
  color: #fff;
  text-align: center;
}

.main_desc {
  margin-top: 50px;
  font-family: "Raleway";
  font-size: 30px;
  color: #fff;
  text-align: center;
}

.main_button {
  margin-top: 50px;
  font-family: "Raleway";
  font-size: 17px;
  color: #fff;
  padding: 20px 30px;
  border-radius: 35px;
  background: linear-gradient(135deg, #ff8800 0%, #cf3218 100%);
  /* background-color: #236ad6; */
  text-transform: uppercase;
}

.main_button:hover {
  background: linear-gradient(135deg, #cb540a 0%, #cf3218 100%);
  /* background-color: #3b8ac3; */
  color: #eedbcb;
}

@media screen and (max-width: 767.5px) {
  .banner_content {
    padding-top: 50px;
  }
  .main_title {
    font-size: 40px;
  }
  .main_desc {
    font-size: 20px;
  }
  .main_button {
    margin-top: 30px;

    font-size: 14px;

    padding: 15px 25px;
    border-radius: 35px;
  }
}
