.copy_sec {
  padding: 25px 0;
}

.copy_text {
  text-align: center;
  font-family: "Raleway";
  font-size: 14px;
  color: #999;
  margin: 0;
}
