.aboutus_box {
  display: flex;
  justify-content: space-between;
  background-color: #ff8800;
  width: 100%;
  /* height: 200px; */
  border-radius: 10px;
  box-shadow: 2px 4px 25px -13px rgba(0, 0, 0, 0.75);
}

.aboutus_left {
  width: 50%;
  padding: 30px;
}

.aboutus_right {
  width: 50%;
}

.aboutus_img {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.aboutus_title {
  font-family: "Raleway";
  font-weight: 500;
  color: #fff;
  font-size: 30px;
  text-align: center;
}

.aboutus_text {
  font-family: "Raleway";
  font-weight: 400;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 1199.5px) {
}

@media screen and (max-width: 767.5px) {
  .aboutus_right {
    display: none;
  }
  .aboutus_left {
    width: 100%;
    padding: 30px;
  }
}
